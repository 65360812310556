import { NavLink, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import { AuthStatus } from '../pages/Auth'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import AddIcon from '@mui/icons-material/Add'
import { useUser } from '@supabase/auth-helpers-react'
import { useSupaGetTeams } from '../data/teams'
import { observer } from 'mobx-react-lite'
import { useAppState } from '../data/app_state'
import logo from '../assets/logo.png'
import { Box, Drawer, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

export interface SimpleDialogProps {
  open: boolean
  selectedValue: any
  onClose: (value: any) => void
}

function TeamDialogue(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props

  const { data: teamsList } = useSupaGetTeams()

  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleListItemClick = (value: any) => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select a Team to Work On</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem disableGutters>
          <ListItemButton autoFocus onClick={() => handleListItemClick({ type: 'myBlocks' })}>
            <ListItemText primary="My Blocks" />
          </ListItemButton>
        </ListItem>
        {teamsList &&
          teamsList.map((team) => (
            <ListItem disableGutters key={`odfnj${team.id}`}>
              <ListItemButton onClick={() => handleListItemClick({ type: 'team', data: team })}>
                <ListItemText primary={team.name} />
              </ListItemButton>
            </ListItem>
          ))}
        <ListItem disableGutters>
          <ListItemButton autoFocus onClick={() => handleListItemClick({ type: 'newTeam' })}>
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Create New Team" />
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog>
  )
}

function Nav() {
  const [open, setOpen] = React.useState(false)
  const navigate = useHistory()
  const user = useUser()
  const appState = useAppState()

  const [drawer, setDrawer] = React.useState(false)

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setDrawer(open)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value: any) => {
    if (!!value) {
      if (value.type === 'newTeam') {
        navigate.push(`/settings?newTeam`)
      } else if (value.type === 'myBlocks') {
        appState.changeTeamContext(null)
      } else {
        appState.changeTeamContext(value.data)
      }
    }

    setOpen(false)
  }

  return (
    <div className="nav">
      <div className="hamburg">
        <IconButton size="small" onClick={() => setDrawer(!drawer)}>
          <MenuIcon style={{ width: '30px', height: '30px' }} />
        </IconButton>
        <Drawer anchor={'bottom'} open={drawer} onClose={toggleDrawer(false)}>
          <Box
            className="drawernav"
            sx={{ width: 'auto', paddingBottom: '20px' }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}>
            <div>
              <AuthStatus mode={'mobile'} />
            </div>
            {user && (
              <div className="teamstoggle hamburgnav">
                <Button variant="outlined" onClick={handleClickOpen}>
                  {appState.teamContext ? appState.teamContext.name : 'MY BLOCKS'}
                </Button>
              </div>
            )}
            <div>
              <NavLink
                to={'/'}
                exact
                className={(isActive) => (isActive ? 'nounder active' : 'nounder')}
                style={{ color: 'black' }}>
                BLOCKS
              </NavLink>
            </div>
            <div>
              <NavLink
                to={'/market'}
                className={(isActive) => (isActive ? 'nounder active' : 'nounder')}
                style={{ color: 'black' }}>
                MARKET
              </NavLink>
            </div>
          </Box>
        </Drawer>
      </div>
      <div className="left side">
        <NavLink
          to={'/'}
          exact
          className={(isActive) => (isActive ? 'nounder active' : 'nounder')}
          style={{ color: 'black', padding: '5px' }}>
          BLOCKS
        </NavLink>
        <NavLink
          to={'/market'}
          className={(isActive) => (isActive ? 'nounder active' : 'nounder')}
          style={{ color: 'black', padding: '5px' }}>
          MARKET
        </NavLink>
      </div>
      <img src={logo} style={{ width: '100px', height: 'auto' }} alt="" />
      <div className="right side" style={{ gap: '8px' }}>
        {user && (
          <div className="teamstoggle mainnav">
            <Button variant="outlined" onClick={handleClickOpen}>
              {appState.teamContext ? appState.teamContext.name : 'MY BLOCKS'}
            </Button>
          </div>
        )}
        <AuthStatus mode={'standard'} />
      </div>
      {user && open && <TeamDialogue selectedValue={null} open={open} onClose={handleClose} />}
    </div>
  )
}

export default observer(Nav)
