import { GeoJSON } from 'geojson'
import { makeAutoObservable } from 'mobx'

export interface GroupOptionType {
  inputValue?: string
  name: string
  id?: number
}

export class Block {
  id?: number
  geo: GeoJSON
  name?: string
  block_groups?: GroupOptionType
  default_itemId?: number
  default_item?: Item
  team_id?: number
  datatrackers?: any[]
  is_global?: boolean
}

export class Item {
  id?: number
  name?: string
}

export class Planting {
  id?: number
  description?: string
  starting_date?: string
  ending_date?: string
  onMarket?: boolean
  block?: Block
  item?: Item
  price?: number
  transaction_side?: string
  display_name?: string
  quantity?: number
  units?: string
  photos?: any[] = []

  constructor(block?: Block) {
    this.block = block
    this.onMarket = false
    this.transaction_side = 'sell'
    this.units = 'ton'
  }
}

export class MapState {
  bounds?: google.maps.LatLngBounds

  constructor() {
    makeAutoObservable(this)
  }
}
