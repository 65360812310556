import { useEffect, useState } from 'react'

const useSupaGet = (supaRequest, transformData, wait) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [refreshTrigger, setRefreshTrigger] = useState(true) // set this to true when want to make the call

  useEffect(() => {
    let inProgress = false

    const fetchData = () => {
      setIsLoading(true)
      inProgress = true

      console.log('calling sb')
      supaRequest()
        .then((response) => {
          if (response.error) {
            throw response.error
          }

          setError(null)
          if (transformData !== undefined) {
            setData(transformData(response.data))
          } else {
            setData(response.data)
          }
        })
        .catch((error) => {
          setError(error)
        })
        .finally(() => {
          inProgress = false
          setIsLoading(false)
        })
    }

    if (refreshTrigger && !inProgress) {
      if (!wait) {
        fetchData()
        setRefreshTrigger(false)
      }
    }
  }, [supaRequest, transformData, refreshTrigger, wait])

  return { data, isLoading, error, refresh: () => setRefreshTrigger(true) }
}

export { useSupaGet }
