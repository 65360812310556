import { Switch, Route, useHistory } from 'react-router-dom'

import MapPage from './pages/MapPage'
import { LoginPage } from './pages/Auth'

import './styles/App.css'
import ActionBar from './components/ActionBar'
import Nav from './components/Nav'
import { SettingsPage } from './pages/Settings'
import { useEffect } from 'react'
import { useSessionContext, useUser } from '@supabase/auth-helpers-react'

function App() {
  const user = useUser()
  let navigate = useHistory()
  const { isLoading } = useSessionContext()

  useEffect(() => {
    if (!isLoading && !user) {
      navigate.push('/market')
    }
  }, [user, navigate, isLoading])

  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="*">
        <div className="app">
          <Nav />
          <ActionBar />
          <Switch>
            <Route path="/settings">
              <SettingsPage />
            </Route>
            <Route path="*">
              <div className="body">
                <MapPage />
              </div>
            </Route>
          </Switch>
        </div>
      </Route>
    </Switch>
  )
}

export default App
