import Card from '@mui/material/Card'
import { useEffect, useMemo, useState } from 'react'
import { useRouteMatch, Switch, Route, useParams, useHistory, useLocation, Link } from 'react-router-dom'
import React from 'react'
import { supabaseClient } from '../config/supabase'
import { Button, Typography } from '@mui/material'
import { useAppState } from '../data/app_state'
import { observer } from 'mobx-react-lite'
import CardMedia from '@mui/material/CardMedia'
import { Modal } from '@mui/material'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

function MarketDetailPlanting({ p }) {
  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleImageClick = (image) => {
    setSelectedImage(image)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Card
        style={{
          margin: '10px 0',
          display: 'flex',
          flexDirection: 'column',
          padding: '5px',
        }}
        key={`iokje${p.id}`}>
        {p.planting_photos && p.planting_photos.length > 0 && (
          <div style={{ display: 'flex', marginBottom: '10px', width: '100%', overflow: 'auto' }}>
            {p.planting_photos.map((x) => (
              <CardMedia
                key={`wefwef${x.id}`}
                component="img"
                height="140"
                image={`https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/user_photos/${x.url}`}
                style={{ width: 'auto', height: '100px', marginRight: '5px', cursor: 'pointer' }}
                onClick={() => handleImageClick(x)}
              />
            ))}
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
            <img
              style={{ width: '20px', height: '20px' }}
              src={
                'https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/site-assets/icons/' +
                p.item.icon_name +
                '.png'
              }
              alt={p.item.icon_name}
            />
            <span>{p.item.name}</span>
          </div>
          <div>
            {p.quantity !== null && `${p.quantity} ${p.units ? ` ${p.units}s ` : ' tons '}`}
            {p.price !== null && '@ $' + p.price + (p.units ? `/${p.units}` : '/ton')}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '2px' }}>
          <div style={{ whiteSpace: 'pre-line', fontStyle: 'italic', flex: 1 }}>{p.description}</div>
          <div>
            {p.starting_date} - {p.ending_date}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px', gap: '20px' }}>
          <Button variant="contained">Contact</Button>
          <Button variant="contained">Buy</Button>
        </div>
      </Card>

      <Modal open={open} onClose={handleClose}>
        <div
          onClick={handleClose}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <img
            src={`https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/user_photos/${selectedImage?.url}`}
            alt={selectedImage?.id}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </div>
      </Modal>
    </>
  )
}

function MarketBlockDetail({ setFocusedBlock }: { setFocusedBlock?: (fb: any) => void }) {
  const x = useParams()
  const y = useQuery()

  const blockID = x.blockID
  const plantingID = y.get('planting')

  const [data, setData] = useState(null)

  useEffect(() => {
    async function getDetailData() {
      let query = supabaseClient
        .from('plantings')
        .select(`*, item:items(*), block:blocks!inner(*), planting_photos(*)`)
        .eq('blocks.id', blockID)
        .eq('onMarket', true)
        .order('market_sort', { foreignTable: 'planting_photos', ascending: true })

      if (!!plantingID) {
        query = supabaseClient
          .from('plantings')
          .select(`*, item:items(*), block:blocks!inner(*), planting_photos(*)`)
          .eq('id', plantingID)
          .eq('onMarket', true)
          .order('market_sort', { foreignTable: 'planting_photos', ascending: true })
      }
      const { data } = await query
      setData(data)
    }

    getDetailData()
  }, [blockID, plantingID])

  useEffect(() => {
    setFocusedBlock(data && data.length > 0 && data[0].block ? data[0].block : null)
    return () => setFocusedBlock(null)
  }, [data, setFocusedBlock])

  if (data && data.length > 0 && data[0].block) {
    const block = data[0].block
    return (
      <div>
        <Link style={{ marginBottom: '5px', display: 'block' }} to="/market">
          View Market
        </Link>
        <div>
          <Typography variant="h4">{block.name}</Typography>
          <div>{data && data.map((p) => <MarketDetailPlanting key={`wergfeg${p.id}`} p={p} />)}</div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

function MarketTab({
  setBlocksData,
  setFocusedBlock,
}: {
  setBlocksData: (x: any) => void
  setFocusedBlock?: (fb: any) => void
}) {
  const appState = useAppState()
  const filters = appState.filterState
  const boundaryFilter = filters.boundaries
  const itemFilter = filters.items

  const [data, setMarketData] = useState(null)

  let match = useRouteMatch()
  const navigate = useHistory()

  useEffect(() => {
    async function getMarket() {
      const { data } = await supabaseClient.rpc('get_market', {
        boundary_ids: boundaryFilter && boundaryFilter.length > 0 ? boundaryFilter.map((b) => b.id) : [],
        item_ids: itemFilter && itemFilter.length > 0 ? itemFilter : [],
      })
      setMarketData(data)
    }

    getMarket()
  }, [boundaryFilter, itemFilter])

  let filteredData = useMemo(() => {
    if (filters) {
      const yearArr = filters && filters.year

      return (
        data &&
        data.filter((d) => {
          const ed = new Date(d.ending_date)
          const edy = ed.getFullYear()

          let yearYes = true
          if (yearArr && yearArr.length > 0) {
            if (edy < Math.min(...yearArr) || edy > Math.max(...yearArr)) {
              yearYes = false
            }
          }

          return yearYes
        })
      )
    } else {
      return data
    }
  }, [data, filters])

  useEffect(() => {
    if (filteredData) {
      let blocksArr = []
      let blocksAdded = {}
      filteredData.forEach((p) => {
        const plantingObj = {
          id: p.id,
          description: p.description,
          price: p.price,
          ending_date: p.ending_date,
          starting_date: p.starting_date,
          item: { id: p.itemId, icon_name: p.item_icon_name },
        }
        const added = blocksAdded[p.block_id]
        if (!!added) {
          const newBlock = { ...added, plantings: [...added.plantings, plantingObj] }
          blocksArr = [...blocksArr.filter((x) => x.id !== added.id), newBlock]
          blocksAdded[p.block_id] = newBlock
        } else {
          const newBlock = {
            ...{
              id: p.block_id,
              geo: p.block_geo,
              name: p.block_name,
              default_item:
                p.block_item_id === null ? null : { icon_name: p.block_item_icon_name, id: p.block_item_id },
            },
            plantings: [plantingObj],
          }
          blocksArr = [...blocksArr, newBlock]
          blocksAdded[p.block_id] = newBlock
        }
      })

      setBlocksData(blocksArr)
    }
  }, [filteredData, setBlocksData])

  return (
    <div style={{ height: '100%' }}>
      <Switch>
        <Route path={match.path} exact>
          <div className="fullHeightTab">
            <div className="fixedHeader">
              <div style={{ marginBottom: '10px' }}>ON THE MARKET</div>
            </div>
            <div className="scrollableBody MarketCont">
              {filteredData &&
                filteredData.map((p) => (
                  <Card
                    className="MarketCard"
                    onClick={() => navigate.push(`${match.path}/${p.block_id}?planting=${p.id}`)}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    key={`iokje${p.id}`}>
                    {p.photo_url ? (
                      <CardMedia
                        component="img"
                        height="140"
                        image={`https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/user_photos/${p.photo_url}`}
                      />
                    ) : null}
                    <div style={{ padding: '5px' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '2px',
                        }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                          <img
                            style={{ width: '20px', height: '20px' }}
                            src={
                              'https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/site-assets/icons/' +
                              p.item_icon_name +
                              '.png'
                            }
                            alt={p.item_icon_name}
                          />
                          <span>{p.item_name}</span>
                        </div>
                      </div>
                      <div>{p.display_name}</div>
                      <div>
                        {p.quantity !== null && `${p.quantity} ${p.units ? ` ${p.units}s ` : ' tons '}`}
                        {p.price !== null && '@ $' + p.price + (p.units ? `/${p.units}` : '/ton')}
                      </div>
                      <div>
                        {p.starting_date} - {p.ending_date}
                      </div>
                    </div>
                  </Card>
                ))}
              {data && (!filteredData || filteredData.length === 0) && <div>No plantings meet your criteria</div>}
            </div>
          </div>
        </Route>
        <Route path={`${match.path}/:blockID`}>
          <MarketBlockDetail setFocusedBlock={(fb) => setFocusedBlock(fb)} />
        </Route>
      </Switch>
    </div>
  )
}

export default observer(MarketTab)
