import {
  Typography,
  TextField,
  Autocomplete,
  Button,
  createFilterOptions,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material'
import { useSupaGetBlockGroups } from '../data/blockGroups'
import { GroupOptionType } from '../helper/types'
import { observer } from 'mobx-react-lite'
import { CreateBlockState, useAppState } from '../data/app_state'
import { createUpdateBlock } from '../data/blocks'
import { useUser } from '@supabase/auth-helpers-react'
import { useSupaGetItems } from '../data/items'
import { useSupaGetTeams } from '../data/teams'
import { useHotkeys } from 'react-hotkeys-hook'

const filter = createFilterOptions<GroupOptionType>()

function CreateBlock({
  createState,
  onDidSubmit,
  global,
}: {
  createState: CreateBlockState
  onDidSubmit: () => void
  global: boolean
}) {
  const appState = useAppState()
  const user = useUser()

  const { data: blockGroups } = useSupaGetBlockGroups()
  const { data: teamsList } = useSupaGetTeams()

  const { data: itemsD } = useSupaGetItems()
  const items = itemsD ? itemsD.asArray : null

  const blockHasBeenDrawn = !!createState.geo

  useHotkeys('s', () => {
    onSubmitClicked()
  })

  const onSubmitClicked = async () => {
    await createUpdateBlock(createState, user, global)

    appState.endBlockCreation()
    onDidSubmit()
  }

  return (
    <div style={{ marginBottom: '20px' }}>
      {!createState.isEdit && <Typography>New Block</Typography>}
      {blockHasBeenDrawn ? (
        <div>
          <TextField
            required
            label="Name Your Block"
            size="small"
            type="text"
            value={createState.name ?? ''}
            onChange={(e) => (createState.name = e.target.value)}
            fullWidth
          />
        </div>
      ) : (
        'Draw a block on the map....'
      )}
      {blockHasBeenDrawn && (
        <div style={{ margin: '10px 0' }}>
          <div>
            <FormControl fullWidth>
              <InputLabel size="small" id="teamlwefwe">
                Team
              </InputLabel>
              <Select
                size="small"
                label="Team"
                id="teamx"
                labelId="teamlwefwe"
                value={teamsList && createState.teamID ? createState.teamID : ''}
                onChange={(e) => (createState.teamID = e.target.value === '' ? null : Number(e.target.value))}>
                <MenuItem value="">None</MenuItem>
                {teamsList &&
                  teamsList.map((team) => (
                    <MenuItem key={`rth${team.id}`} value={team.id}>
                      {team.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <Autocomplete
            value={createState.blockGroup ?? { name: '' }}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                createState.blockGroup = {
                  name: newValue,
                }
              } else if (newValue && newValue.inputValue) {
                createState.blockGroup = {
                  name: newValue.inputValue,
                }
              } else {
                createState.blockGroup = newValue
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)
              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.name)
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `Add "${inputValue}"`,
                })
              }
              return filtered
            }}
            style={{ margin: '10px 0' }}
            selectOnFocus
            freeSolo
            clearOnBlur
            handleHomeEndKeys
            options={blockGroups ? blockGroups : []}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option
              }
              if (option.inputValue) {
                return option.inputValue
              }
              return option.name
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.name + option.id}>
                {option.name}
              </li>
            )}
            size="small"
            renderInput={(params) => <TextField {...params} label="Add Block to a Group" />}
          />
          <div style={{ margin: '10px 0' }}>
            <FormControl required fullWidth>
              <InputLabel size="small" id="defaultItem">
                Default Item
              </InputLabel>
              <Select
                size="small"
                label="Default Item"
                id="defaultItems"
                labelId="defaultItem"
                value={items && createState.defaultItemID ? createState.defaultItemID : ''}
                onChange={(e) => (createState.defaultItemID = Number(e.target.value))}>
                {items &&
                  items.map((item) => (
                    <MenuItem key={`gedwr${item.id}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {blockHasBeenDrawn && !!createState.name && createState.name !== '' && (
          <Button variant="contained" onClick={onSubmitClicked}>
            SUBMIT
          </Button>
        )}
        <Button variant="outlined" onClick={() => appState.endBlockCreation()}>
          CANCEL
        </Button>
      </div>
    </div>
  )
}

export default observer(CreateBlock)
