import { useSupaGet } from './supabaseHelpers'
import { supabaseClient } from '../config/supabase'

const useSupaGetMyBlocks = (user) => {
  const req = () => {
    return supabaseClient
      .from('blocks')
      .select(`*, block_groups(*), default_item: items(*), plantings(*, item:items(*))`)
      .eq('owner', user.id)
  }

  const transformData = (data) => {
    let groupBlocks = {}
    let groupData = {}
    let ungrouped = []
    data.forEach((block) => {
      if (block.block_groups) {
        let x = []
        if (groupBlocks[block.block_groups.id]) {
          x = groupBlocks[block.block_groups.id]
        }
        x.push(block)
        groupBlocks[block.block_groups.id] = x
        groupData[block.block_groups.id] = block.block_groups
      } else {
        ungrouped.push(block)
      }
    })
    let grouped = []
    for (const [key, value] of Object.entries(groupData)) {
      grouped = [...grouped, { ...value, blocks: groupBlocks[key] }]
    }
    return { grouped, ungrouped }
  }

  const x = useSupaGet(req, transformData, !user)

  return x
}

const useSupaGetBlockDetail = (id) => {
  const req = () => {
    return supabaseClient
      .from('blocks')
      .select(
        `*, teams(*), plantings(*, item:items(*)), block_groups(*), block_boundaries(block_contained, boundaries(id, name, tree_path)),
          default_item: items(*), datatrackers(*, responses: datatracker_responses(*, user: profiles(*)))`
      )
      .eq('id', id)
      .eq('datatrackers.datatracker_responses.block_id', id)
      .single()
      .order('created_at', { foreignTable: 'datatrackers.responses', ascending: false })
  }

  const x = useSupaGet(req)

  return x
}

const createUpdateBlock = async (newBlock, user, global) => {
  // should eventually move this to db function
  let blockGroupID = null
  if (newBlock.blockGroup) {
    if (newBlock.blockGroup.id) {
      blockGroupID = newBlock.blockGroup.id
    } else {
      const { data, error } = await supabaseClient
        .from('block_groups')
        .insert({ name: newBlock.blockGroup.name, owner: user.id })
        .select()
        .single()

      if (!error) {
        blockGroupID = data.id
      }
    }
  }

  if (newBlock.isEdit) {
    // can use update because do not support updating block geo
    let x = {
      name: newBlock.name,
      groupId: blockGroupID,
      default_itemId: newBlock.defaultItemID,
      team_id: newBlock.teamID,
    }
    return await supabaseClient.from('blocks').update(x).eq('id', newBlock.id)
  } else {
    let { data, error } = await supabaseClient.rpc('create_block', {
      block_group_id: blockGroupID,
      default_item_id: newBlock.defaultItemID === undefined ? null : newBlock.defaultItemID,
      geo: newBlock.geo,
      name: newBlock.name,
      user_id: user.id,
      team_id: newBlock.teamID === undefined ? null : newBlock.teamID,
      _is_global: !!global,
    })

    if (error) console.error(error)
    return data
  }
}

const deleteBlock = async (blockid) => {
  return await supabaseClient.from('blocks').delete().eq('id', blockid)
}

export { useSupaGetMyBlocks, useSupaGetBlockDetail, createUpdateBlock, deleteBlock }
