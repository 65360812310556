import { configure, makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import { GeoJSON } from 'geojson'
import { Block, GroupOptionType, MapState } from '../helper/types'

configure({ enforceActions: 'never' })

class AppState {
  createBlock?: CreateBlockState
  filterState?: any
  teamContext?: any
  mapState?: MapState

  constructor() {
    this.filterState = {
      year: [2023, 2030],
      items: [],
      boundaries: [],
    }
    this.mapState = new MapState()
    makeAutoObservable(this)
  }

  updateFilter(newFilterState) {
    this.filterState = newFilterState
  }

  get isInCreationMode() {
    return !!this.createBlock
  }

  endBlockCreation() {
    this.createBlock = undefined
  }

  beginBlockCreation(is_global: boolean) {
    this.createBlock = new CreateBlockState(undefined, is_global)
  }

  beginBlockEdit(block: Block) {
    this.createBlock = new CreateBlockState(block)
  }

  changeTeamContext(newTeam) {
    this.teamContext = newTeam
  }

  updateMapBounds(newBounds: google.maps.LatLngBounds) {
    this.mapState.bounds = newBounds
  }
}

export class CreateBlockState {
  isEdit: boolean = false

  id?: number
  geo?: GeoJSON
  name?: string
  blockGroup?: GroupOptionType
  defaultItemID?: number
  teamID?: number
  is_global?: boolean

  constructor(existingBlock?: Block, is_global?: boolean) {
    makeAutoObservable(this)

    this.is_global = !!is_global

    if (!!is_global) {
      if (!!!existingBlock) {
        this.name = 'Global'
      }
    }

    if (existingBlock) {
      this.isEdit = true
      this.id = existingBlock.id
      this.name = existingBlock.name
      this.blockGroup = existingBlock.block_groups
      this.geo = existingBlock.geo
      this.defaultItemID = existingBlock.default_itemId
      this.teamID = existingBlock.team_id
    }
  }
}

const state = new AppState()

export const AppStateContext = createContext<AppState>(state)

export const useAppState = () => {
  return useContext(AppStateContext)
}
