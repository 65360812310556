import { useSupaGet } from './supabaseHelpers'
import { supabaseClient } from '../config/supabase'

const useSupaGetBoundaries = () => {
  const req = () => {
    return supabaseClient
      .from('boundaries_with_box')
      .select(`id, name, tree_path, bbox`)
      .order('sort', { ascending: false })
      .order('name', { ascending: true })
  }

  const x = useSupaGet(req, (itemsarr) => {
    var root = itemsarr.reduce(
      (children, item) => {
        children(item.tree_path.replace(/(^|\.)\w+$/g, '')).push({
          ...item,
          children: children(item.tree_path),
        })

        return children
      },
      function (key) {
        return this[key] || (this[key] = [])
      }.bind({})
    )('')

    return { asArray: itemsarr, asTree: root }
  })

  return x
}

export { useSupaGetBoundaries }
