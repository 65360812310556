import { useHistory, useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import { supabaseClient } from '../config/supabase'
import { useUser } from '@supabase/auth-helpers-react'

import { Auth, ThemeSupa } from '@supabase/auth-ui-react'
import { useEffect } from 'react'

interface LocationState {
  from: {
    pathname: string
  }
}

function AuthStatus({ mode }) {
  const user = useUser()
  let navigate = useHistory()
  let location = useLocation()

  if (!user) {
    return (
      <div>
        <Button onClick={() => navigate.replace('/login', { from: location })}>LOGIN</Button>
      </div>
    )
  }

  return (
    <div className={`AuthStatus ${mode}`}>
      {user.email}
      <Button
        onClick={() => {
          supabaseClient.auth.signOut().finally(() => {
            navigate.push('/')
            window.location.reload()
          })
        }}>
        Sign out
      </Button>
    </div>
  )
}

function LoginPage() {
  const user = useUser()
  let navigate = useHistory()
  let location = useLocation()

  useEffect(() => {
    if (user) {
      const { from } = location.state as LocationState
      let froms = from?.pathname || '/'

      navigate.replace(froms)
    }
  }, [location.state, navigate, user])

  return (
    <div style={{ maxWidth: '300px', margin: 'auto' }}>
      <Auth supabaseClient={supabaseClient} appearance={{ theme: ThemeSupa }} />
    </div>
  )
}

export { LoginPage, AuthStatus }
