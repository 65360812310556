import { useSupaGet } from './supabaseHelpers'
import { supabaseClient } from '../config/supabase'

const useSupaGetBlockGroups = () => {
  const req = () => {
    return supabaseClient.from('block_groups').select(`*`)
  }

  const x = useSupaGet(req)

  return x
}

export { useSupaGetBlockGroups }
