import TextField from '@mui/material/TextField'
import '../styles/SettingsPage.css'
import { useState } from 'react'
import Button from '@mui/material/Button'
import { createTeam, createTeamMember, useSupaGetTeams } from '../data/teams'
import { useUser } from '@supabase/auth-helpers-react'
import IconButton from '@mui/material/IconButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import Chip from '@mui/material/Chip'

function TeamNode({team, user,  addMember}: any) {
    const [addMode, setAddMode] = useState(false)
    const [newMember, setNewMember] = useState(null)

    const clear =() => {
        setAddMode(false)
        setNewMember(null)
    }    

    return (
        <div style={{marginBottom: '20px'}}>
            <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                <div>{team.name}</div> <div style={{marginLeft: '10px'}}>{team.owner === user.id ? <Chip size="small" label="Owner" /> : <Chip size="small" label="Member" />}</div>
            {
                team.owner === user.id
                &&
                (
                    addMode ?
                    (
                        <div style={{flex: 1,marginLeft: '5px', display: 'flex', alignItems: 'center'}}>
                            <TextField
                                required
                                label="Email"
                                size="small"
                                type="text"
                                style={{width: '100%', maxWidth: '300px'}}
                                value={newMember ? newMember : ''}
                                onChange={(e) => setNewMember(e.target.value)}
                            />
                            <IconButton onClick={() => {addMember(newMember);clear();}}>
                                <AddCircleIcon />
                            </IconButton>
                            <IconButton onClick={() => {setAddMode(false);clear();}}>
                                <CancelIcon />
                            </IconButton>
                        </div>
                    ) :
                    (
                        <IconButton onClick={() => setAddMode(true)}>
                            <AddCircleIcon />
                        </IconButton>
                    )
                )
            }
            </div>
        {
            <div style={{marginBottom: '5px', padding: '0 0 0 10px', maxHeight: '100px', overflowY: 'auto'}}>
                {
                    team.owner !== user.id
                    &&
                    (
                        <div style={{marginBottom: '3px', display: 'flex', alignItems: 'center'}}>
                            <div key={`iwuedbed`}>{team.owner_profile.email}</div><div style={{marginLeft: '10px'}}><Chip size="small" label="Owner" /></div>
                        </div>
                    )
                }
                {   
                    team.team_members && team.team_members.length > 0
                    ?
                    team.team_members.map(tm => (
                        <div key={`iwuedb${tm.id}`}>{tm.email}</div>
                    )):
                    (
                        'No Members'
                    )
                }
            </div>
        }
        </div>
    )
}

function SettingsPage() {
    const [newTeam, setNewTeam] = useState(null)
    const user = useUser()
    
    const { data: teamsList, refresh } = useSupaGetTeams()

    const submitNewTeam = async (nt) => {
        await createTeam(nt, user)
        await refresh()
        setNewTeam(null)
    }

    const submitNewTeamMember = async (email, teamID) => {
        await createTeamMember(email.trim(), teamID)
        await refresh()
        setNewTeam(null)
    }

    if (!!!user) {
        return null
    }    

    return (
        <div className='container'>
            <div style={{marginBottom: '10px'}}>SETTINGS</div>
            <div>
                <div>
                    <div style={{marginBottom: '5px'}}>Teams</div>
                    <div style={{marginBottom: '5px', padding: '0 0 0 10px'}}>
                        {
                            teamsList &&
                            teamsList.map(t => (
                                <TeamNode team={t} user={user} addMember={(email) => submitNewTeamMember(email, t.id)}  key={`uiohcwebhr${t.id}`} />
                            ))
                        }
                    </div>
                    <div>
                        <div style={{marginBottom: '5px'}}>Create New Team</div>
                        <div style={{marginBottom: '5px'}}>
                        <TextField
                            required
                            label="Name"
                            size="small"
                            type="text"
                            style={{width: '100%', maxWidth: '300px'}}
                            value={newTeam ? newTeam.name : ''}
                            onChange={(e) => setNewTeam({ ...newTeam, name: e.target.value })}
                        />
                        </div>
                        <Button variant="contained" onClick={() => submitNewTeam(newTeam)}>
                            CREATE
                        </Button>
                    </div>       
                </div>
            </div>
        </div>
    )
}

export { SettingsPage }