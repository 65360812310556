import { supabaseClient } from '../config/supabase'
import { useSupaGet } from './supabaseHelpers'

const useSupaGetTeams = () => {
  const req = () => {
    // either here or in RLS, need to filter teams by either user is owner or is team_member (user email = teammember email)
    // below will not work, need db function or RLS to solve this
    return supabaseClient.from('teams').select(`*, team_members(*), owner_profile:profiles(email)`)
  }

  const x = useSupaGet(req)

  return x
}

const createTeam = async (newTeam, user) => {
  const x = {
    name: newTeam.name,
    owner: user.id
  }

  return await supabaseClient.from('teams').insert(x)
}

const createTeamMember = async (newMemberEmail, teamID) => {
  const x = {
    email: newMemberEmail,
    team: teamID
  }

  return await supabaseClient.from('team_members').insert(x)
}

export { createTeam, useSupaGetTeams, createTeamMember }
