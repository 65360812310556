import { useSupaGet } from './supabaseHelpers'
import { supabaseClient } from '../config/supabase'

const useSupaGetAvailableDatatrackers = (userID, teamID) => {
  const req = () => {
    if (!teamID) {
      return supabaseClient
        .from('datatrackers')
        .select(`*`)
        .or(`global.eq.true,and(team_id.is.null, owner_id.eq.${userID})`)
    } else {
      return supabaseClient.from('datatrackers').select(`*`).or(`global.eq.true,team_id.eq.${teamID})`)
    }
  }

  const x = useSupaGet(req, undefined, !userID)

  return x
}

const deleteDataTrackerAssignment = async (dtid, blockid) => {
  return await supabaseClient
    .from('datatracker_assignments')
    .delete()
    .eq('datatracker_id', dtid)
    .eq('block_id', blockid)
}

const addDataTrackerAssignment = async (dtid, blockid) => {
  return await supabaseClient.from('datatracker_assignments').insert({ datatracker_id: dtid, block_id: blockid })
}

const useSupaGetTeamRules = (user, teamId) => {
  const req = () => {
    if (!!teamId) {
      return supabaseClient
        .from('datatracker_rules')
        .select(`*, item: items(*), datatracker: datatrackers(*)`)
        .eq('team', teamId)
    } else {
      return supabaseClient
        .from('datatracker_rules')
        .select(`*, item: items(*), datatracker: datatrackers(*)`)
        .eq('owner', user.id)
        .is('team', null)
    }
  }

  const x = useSupaGet(req, undefined, !user)

  return x
}

const deleteDataTrackerRule = async (ruleId) => {
  return await supabaseClient.from('datatracker_rules').delete().eq('id', ruleId)
}

const addDataTrackerRule = async (newRule) => {
  return await supabaseClient.from('datatracker_rules').insert({
    datatracker: newRule.datatracker,
    team: newRule.team,
    item_type: newRule.item_type,
    owner: newRule.owner,
  })
}

const getBlockDataTrackers = async (blockID) => {
  return await supabaseClient.rpc('get_block_datatrackers', { _block_id: Number(blockID) })
}

export {
  useSupaGetAvailableDatatrackers,
  deleteDataTrackerAssignment,
  addDataTrackerAssignment,
  useSupaGetTeamRules,
  deleteDataTrackerRule,
  addDataTrackerRule,
  getBlockDataTrackers,
}
