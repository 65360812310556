import { supabaseClient } from '../config/supabase'

const createUpdatePlanting = async (newPlanting, user) => {
  const x = {
    id: newPlanting.id,
    description: newPlanting.description,
    owner: user.id,
    ending_date: newPlanting.ending_date,
    starting_date: newPlanting.starting_date,
    onMarket: newPlanting.onMarket,
    itemId: newPlanting.item.id,
    blockId: newPlanting.block.id,
    price: newPlanting.price,

    transaction_side: newPlanting.transaction_side,
    display_name: newPlanting.display_name,
    quantity: newPlanting.quantity,
    units: newPlanting.units,
  }

  const editedPlanting = await supabaseClient.from('plantings').upsert(x).select()

  if (editedPlanting && editedPlanting.data?.length > 0) {
    await supabaseClient.from('planting_photos').delete().match({ planting_id: editedPlanting.data[0].id })
    await supabaseClient.from('planting_photos').insert(
      newPlanting.photos.map((photo, index) => ({
        planting_id: editedPlanting.data[0].id,
        url: photo.url,
        market_sort: index,
      }))
    )
  }

  return editedPlanting
}

export { createUpdatePlanting }
