import {
  Typography,
  IconButton,
  Card,
  Modal,
  Box,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
  InputAdornment,
} from '@mui/material'
import { useUser } from '@supabase/auth-helpers-react'
import { observer } from 'mobx-react-lite'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { deleteBlock } from '../data/blocks'
import {
  addDataTrackerAssignment,
  addDataTrackerRule,
  deleteDataTrackerAssignment,
  deleteDataTrackerRule,
  getBlockDataTrackers,
  useSupaGetAvailableDatatrackers,
  useSupaGetTeamRules,
} from '../data/datatrackers'
import { Block, Planting } from '../helper/types'
import CreateBlock from './CreateBlock'
import { useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import { useSupaGetItems } from '../data/items'
import { createUpdatePlanting } from '../data/plantings'
import { useAppState } from '../data/app_state'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { supabaseClient } from '../config/supabase'
import ImageUploading from 'react-images-uploading'
import { decode } from 'base64-arraybuffer'
import CloseIcon from '@mui/icons-material/Close'
import { Carousel } from 'react-responsive-carousel'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useLocation, useHistory } from 'react-router-dom'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { isAdmin } from '../helper/auth'
import { ArrowBack, ArrowForward } from '@mui/icons-material'

const DT_NONE = 'none'
const DT_REQD = 'required'
const DT_OPT = 'optional'

function BlockDetail({
  blocks,
  setFocusedBlock,
  setBlockData,
  clearMap,
}: {
  blocks: Block[]
  setFocusedBlock?: (fb: any) => void
  setBlockData: (b: any) => void
  clearMap: () => void
}) {
  const appState = useAppState()
  const location = useLocation()
  const user = useUser()
  let history = useHistory()

  const admin_url = location.search.includes('admin')
  const isAdminUser = isAdmin(user)

  const params = useParams()
  const { blockId } = params

  const [plantingModal, setPlantingModal] = useState(null)
  const [datatrackerModal, setDatatrackerModal] = useState(null)
  const [datatrackerEntryModal, setDatatrackerEntryModal] = useState(null)
  const [datatrackerViewModal, setDatatrackerViewModal] = useState(null)
  const [datatrackers, setDatatrackers] = useState(null)
  const [blockDetail, setBlockBlockDetailData] = useState(null)

  const getData = useCallback(async () => {
    supabaseClient
      .from('blocks')
      .select(
        `*, teams(*), plantings(*, item:items(*), photos:planting_photos(*)), block_groups(*), block_boundaries(block_contained, boundaries(id, name, tree_path)),
          default_item: items(*), datatrackers(*, responses: datatracker_responses(*, user: profiles(*)))`
      )
      .eq('id', blockId)
      .eq('datatrackers.datatracker_responses.block_id', blockId)
      .order('created_at', { foreignTable: 'datatrackers.responses', ascending: false })
      .single()
      .then((res) => {
        if (res.data) {
          setBlockBlockDetailData(res.data)
        }
      })
  }, [blockId])

  useEffect(() => {
    if (blockId) {
      getData()
    }
  }, [getData, blockId])

  const onEditClicked = () => {
    appState.beginBlockEdit(blockDetail)
  }

  const onDelete = async () => {
    console.log(blockId)

    await deleteBlock(blockId)
  }

  const getDataTrackers = useCallback(async (blockId) => {
    const dts = await getBlockDataTrackers(blockId)
    setDatatrackers(dts.data)
  }, [])

  useEffect(() => {
    return () => {
      clearMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (blockDetail) {
      setFocusedBlock(blockDetail)
    }

    return () => setFocusedBlock(null)
  }, [blockDetail, setFocusedBlock])

  useEffect(() => {
    if (
      blockDetail &&
      (!blocks ||
        blocks.length >
          1 /* hack to prevent rerender issue but also allow state to change when going from list page to detail*/ ||
        JSON.stringify(blockDetail) !== JSON.stringify(blocks[0])) /* so map updates on edit or new planting */
    ) {
      setBlockData([blockDetail])
    }
  }, [blockDetail, blocks, setBlockData])

  useEffect(() => {
    if (blockId) {
      getDataTrackers(blockId)
    }
  }, [blockId, getDataTrackers])

  if (!blockDetail) {
    return null
  }

  const { name: blockName } = blockDetail

  return (
    <div className="fullHeightTab">
      <div className="fixedHeader">
        <div
          style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Button variant="contained" size="small" onClick={history.goBack}>
            Back
          </Button>
          {blockDetail.is_global && (
            <div style={{ fontSize: '10px', fontStyle: 'italic' }}>This block is managed by Blossom</div>
          )}
        </div>
        {!blockDetail.is_global && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">{blockName}</Typography>
            <IconButton color="primary" onClick={onEditClicked}>
              <EditIcon />
            </IconButton>
          </div>
        )}
        {isAdminUser && (
          <Button color="error" variant="contained" onClick={() => onDelete()}>
            DELETE
          </Button>
        )}
        <div style={{ margin: '10px 0' }}>
          {appState.createBlock ? (
            <CreateBlock
              createState={appState.createBlock}
              onDidSubmit={() => {
                getData()
                getDataTrackers(blockId)
              }}
              global={!!admin_url}
            />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {!blockDetail.is_global && (
                <div>{blockDetail.teams ? 'Team: ' + blockDetail.teams.name : 'My Blocks'}</div>
              )}
              {!blockDetail.is_global && blockDetail.block_groups ? <div>{blockDetail.block_groups.name}</div> : null}
              {blockDetail.default_item && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                  <img
                    style={{ width: '20px', height: '20px' }}
                    src={
                      'https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/site-assets/icons/' +
                      blockDetail.default_item.icon_name +
                      '.png'
                    }
                    alt={blockDetail.default_item.icon_name}
                  />
                  {blockDetail.default_item.name}
                </div>
              )}
              <div style={{ display: 'flex', gap: '3px', flexWrap: 'wrap' }}>
                {blockDetail.block_boundaries &&
                  blockDetail.block_boundaries
                    .sort((a, b) => a.boundaries.tree_path.localeCompare(b.boundaries.tree_path))
                    .map((bb, i) => {
                      const firstPath = bb.boundaries.tree_path.substring(0, bb.boundaries.tree_path.indexOf('.'))
                      return (
                        <Chip
                          key={`dchiyuwef${i}`}
                          size="small"
                          label={`${bb.boundaries.name}${firstPath === 'Countries' ? '' : ' (' + firstPath + ')'}`}
                          variant={bb.block_contained ? 'filled' : 'outlined'}
                        />
                      )
                    })}
              </div>
              <div>Acres: {blockDetail.acres}</div>
            </div>
          )}
        </div>
      </div>
      {!blockDetail.is_global && (
        <div className="scrollableBody">
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <Typography variant="h6">Plantings</Typography>
              <IconButton color="primary" onClick={() => setPlantingModal(new Planting(blockDetail))}>
                <AddIcon />
              </IconButton>
            </div>
            <div>
              {blockDetail.plantings &&
                blockDetail.plantings
                  .sort((a, b) => Date.parse(a.starting_date) - Date.parse(b.starting_date))
                  .map((p) => (
                    <Card
                      onClick={() => setPlantingModal({ ...p, update: true })}
                      style={{
                        cursor: 'pointer',
                        margin: '10px 0',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '5px',
                      }}
                      key={`iokje${p.id}`}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '2px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                          <img
                            style={{ width: '20px', height: '20px' }}
                            src={
                              'https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/site-assets/icons/' +
                              p.item.icon_name +
                              '.png'
                            }
                            alt={p.item.icon_name}
                          />
                          <span>{p.item.name}</span>
                        </div>
                        <div>
                          {p.onMarket
                            ? 'On Market - ' +
                              (p.quantity !== null ? `${p.quantity} ${p.units ? ` ${p.units}s ` : ' tons '}` : '') +
                              (p.price !== null ? '@ $' + p.price + (p.units ? `/${p.units}` : '/ton') : '')
                            : 'Off Market'}
                        </div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '2px' }}>
                        <div>{p.display_name}</div>
                        <div>
                          {p.starting_date} - {p.ending_date}
                        </div>
                      </div>
                    </Card>
                  ))}
              {blockDetail.plantings && blockDetail.plantings.length === 0 && 'No Plantings Yet'}
            </div>
          </div>
          <div className="datatracker">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <Typography variant="h6">Data Trackers</Typography>
              <IconButton color="primary" onClick={() => setDatatrackerModal(blockDetail)}>
                <AddIcon />
              </IconButton>
            </div>
            <div>
              {datatrackers &&
                datatrackers.map((dt) => (
                  <div key={`oneu${dt.id}`}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                      <div>{dt.config.title}</div>
                      <IconButton color="primary" onClick={() => setDatatrackerEntryModal(dt)}>
                        <AddBoxIcon />
                      </IconButton>
                    </div>
                    <div className="responses" style={{ maxHeight: '200px', overflow: 'auto' }}>
                      {dt.responses && dt.responses.length > 0
                        ? dt.responses.map((r) => {
                            const date = new Date(r.created_at)
                            return (
                              <Card
                                onClick={() => setDatatrackerViewModal({ response: r, datatracker: dt })}
                                style={{ marginBottom: '10px', padding: '5px', cursor: 'pointer' }}
                                key={`iahd${r.id}`}>
                                <div>
                                  {!!r.response_data.value && '' + r.response_data.value}
                                  {dt.config.response_type === 'Decimal' &&
                                    dt.config.response_units &&
                                    ` ${dt.config.response_units}`}
                                </div>
                                <div style={{ whiteSpace: 'pre-line', fontStyle: 'italic', margin: '5px 0' }}>
                                  {r.note}
                                </div>
                                <div style={{ fontSize: '10px' }}>
                                  {r.email} -{' '}
                                  {date.toLocaleTimeString('en-us', {
                                    weekday: 'short',
                                    year: 'numeric',
                                    month: 'short',
                                  })}
                                </div>
                                {r.photos && r.photos.length > 0 && (
                                  <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                    {r.photos.map((p) => (
                                      <img
                                        alt=""
                                        key={p}
                                        style={{ maxHeight: '30px', maxWidth: '30px', marginRight: '5px' }}
                                        src={`https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/user_photos/${p}`}
                                      />
                                    ))}
                                  </div>
                                )}
                              </Card>
                            )
                          })
                        : 'No Responses Yet'}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      <Modal open={!!plantingModal} onClose={() => setPlantingModal(null)}>
        <Box className="ModalBox">
          <CreatePlanting
            block={blockDetail}
            update={plantingModal && { ...plantingModal.update }}
            plantingP={plantingModal}
            onSubmit={() => setPlantingModal(null)}
            onSuccess={() => getData()}
          />
        </Box>
      </Modal>
      <Modal
        open={!!datatrackerModal}
        onClose={() => {
          setDatatrackerModal(null)
          getDataTrackers(blockId)
        }}>
        <Box className="ModalBox">
          <ManageDataTrackers
            datatrackers={datatrackers}
            block={blockDetail}
            onSuccess={() => getDataTrackers(blockId)}
          />
        </Box>
      </Modal>
      <Modal
        open={!!datatrackerEntryModal}
        onClose={() => {
          setDatatrackerEntryModal(null)
          getDataTrackers(blockId)
        }}>
        <Box className="ModalBox">
          <DataTrackerEntry
            block={blockDetail}
            datatracker={datatrackerEntryModal}
            onSuccess={() => {
              getDataTrackers(blockId)
              setDatatrackerEntryModal(null)
            }}
            cancel={() => setDatatrackerEntryModal(null)}
          />
        </Box>
      </Modal>
      <Modal open={!!datatrackerViewModal} onClose={() => setDatatrackerViewModal(null)}>
        <Box className="ModalBox Full">
          <DataTrackerView
            block={blockDetail}
            response={datatrackerViewModal && datatrackerViewModal.response}
            datatracker={datatrackerViewModal && datatrackerViewModal.datatracker}
            onSuccess={() => {
              setDatatrackerViewModal(null)
            }}
            cancel={() => setDatatrackerViewModal(null)}
          />
        </Box>
      </Modal>
    </div>
  )
}

function DataTrackerView({
  block,
  datatracker,
  response,
  onSuccess,
  cancel,
}: {
  block: Block
  datatracker: any
  response: any
  onSuccess: () => void
  cancel: () => void
}) {
  if (!datatracker) {
    return null
  }

  const date = new Date(response.created_at)

  return (
    <div className="FullModalInner">
      <IconButton
        onClick={cancel}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}>
        <CloseIcon />
      </IconButton>
      <div>
        <div>
          {!!response.response_data.value && '' + response.response_data.value}
          {datatracker.config.response_type === 'Decimal' &&
            datatracker.config.response_units &&
            ` ${datatracker.config.response_units}`}
        </div>
        <div style={{ whiteSpace: 'pre-line', fontStyle: 'italic', margin: '5px 0' }}>{response.note}</div>
        <div style={{ fontSize: '10px' }}>
          {response.email} - {date.toLocaleTimeString('en-us', { weekday: 'short', year: 'numeric', month: 'short' })}
        </div>
      </div>
      <div className="grower">
        {response.photos && response.photos.length > 0 && (
          <Carousel showArrows={true} showThumbs={false} dynamicHeight={true}>
            {response.photos.map((p, i) => (
              <div key={i}>
                <img
                  alt=""
                  style={{ objectFit: 'contain', maxHeight: '70dvh' }}
                  src={`https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/user_photos/${p}`}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  )
}

function DataTrackerEntry({
  block,
  datatracker,
  onSuccess,
  cancel,
}: {
  block: Block
  datatracker: any
  onSuccess: () => void
  cancel: () => void
}) {
  const {
    title,
    no_response,
    note: config_note,
    photos,
    /* location, */ response_type,
    response_units,
    list_options,
    list_select,
  } = datatracker.config
  const [response, setResponse] = useState('')
  const [note, setNote] = useState('')
  const user = useUser()
  const [images, setImages] = useState([])

  const onImageChange = (imageList) => {
    setImages(imageList)
  }

  const submittable =
    (!Array.isArray(response) || response.length > 0) &&
    (no_response ||
      (!!response &&
        response !== null &&
        (response_type !== 'list' ||
          list_options !== 'multiple' ||
          (Array.isArray(response) && response.length > 0)))) &&
    (config_note !== DT_REQD || note !== '') &&
    (photos !== DT_REQD || images.length > 0)

  const onValueChange = (inp) => {
    const re = /^[0-9]*[.]?[0-9]*$/

    if (inp === '') {
      setResponse('')
      return
    }

    if (response_type === 'Decimal' || response_type === 'onetoten') {
      if (re.test(inp)) {
        setResponse(inp)
      }
    } else {
      setResponse(inp)
    }
  }

  const submit = async () => {
    let imageFileNames = []
    for (let index = 0; index < images.length; index++) {
      const img = images[index]

      const { type, name } = img.file
      const ext = name.split('.').pop()

      const timestamp = new Date().getTime()

      const filename = `${user.id}/${user.id}${timestamp.toString()}.${ext}`

      const { data } = await supabaseClient.storage
        .from('user_photos')
        .upload(filename, decode(img.data_url.split('base64,').pop()), {
          contentType: type,
        })

      if (data) {
        imageFileNames.push(data.path)
      }
    }

    const responseConverted = response_type === 'Decimal' || response_type === 'onetoten' ? Number(response) : response

    const b = {
      response_data: {
        value: responseConverted,
      },
      note: note === '' ? null : note,
      block_id: block.id,
      user_id: user.id,
      datatracker_id: datatracker.id,
      photos: imageFileNames,
    }

    await supabaseClient.from('datatracker_responses').insert(b)

    onSuccess()
  }

  const renderResponse = () => {
    switch (response_type) {
      case 'Decimal':
        return (
          <TextField
            helperText={`Entry type: ${response_type}${!!response_units && ', Units: ' + response_units}`}
            required
            value={response}
            onChange={(e) => onValueChange(e.target.value)}
            fullWidth
            label="Enter Value"
            color="primary"
          />
        )
      case 'onetoten':
        return (
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="onetoten">1 - 10</InputLabel>
            <Select
              labelId="onetoten"
              id="onetotenselect"
              value={response}
              required
              onChange={(e) => onValueChange(e.target.value)}
              label="1 - 10">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                <MenuItem key={`iusdhc${num}`} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      case 'list':
        return (
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120, width: '100%' }}>
            <InputLabel required id="list">
              {list_select === 'multiple' ? 'Select All That Apply' : 'Select an Option'}
            </InputLabel>
            <Select
              labelId="list"
              id="listselect"
              value={list_select === 'multiple' ? (Array.isArray(response) ? response : []) : response}
              required
              multiple={list_select === 'multiple'}
              onChange={(e) => onValueChange(e.target.value)}
              label={list_select === 'multiple' ? 'Select All That Apply' : 'Select an Option'}>
              {list_options.map((num) => (
                <MenuItem key={`iusdhc${num}`} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      default:
        break
    }
  }

  return (
    <div className="DataTrackerEntry">
      <Typography variant="h6" style={{ textAlign: 'center' }}>
        New Entry: {title}
      </Typography>
      <div style={{ margin: '10px 0' }}>
        {!no_response && (
          <div className="DataTrackerEntryResponse" style={{ marginBottom: '10px' }}>
            {renderResponse()}
          </div>
        )}
        {!(config_note === DT_NONE) && (
          <div style={{ marginBottom: '10px' }}>
            <TextField
              fullWidth
              label="Note"
              multiline
              required={config_note === DT_REQD}
              maxRows={5}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        )}
        {!(photos === DT_NONE) && (
          <div style={{ marginBottom: '10px' }}>
            <div style={{ fontSize: '12px', margin: '5px 0' }}>
              Photos {photos === DT_REQD ? 'Required' : 'Optional'}
            </div>
            <ImageUploading multiple value={images} onChange={onImageChange} maxNumber={20} dataURLKey="data_url">
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div className="upload__image-wrapper">
                  <button style={isDragging ? { color: 'red' } : undefined} onClick={onImageUpload} {...dragProps}>
                    Click or Drop here
                  </button>
                  &nbsp;
                  <button onClick={onImageRemoveAll}>Remove all images</button>
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                    }}>
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image['data_url']} alt="" style={{ maxWidth: '100px', height: 'auto' }} />
                        <div className="image-item__btn-wrapper">
                          <button onClick={() => onImageUpdate(index)}>Update</button>
                          <button onClick={() => onImageRemove(index)}>Remove</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>
        )}
      </div>
      <div>
        <div>
          <Button style={{ marginRight: '20px' }} onClick={() => cancel()}>
            CANCEL
          </Button>
          <Button variant="contained" color="primary" disabled={!submittable} onClick={() => submit()}>
            ENTER
          </Button>
        </div>
      </div>
    </div>
  )
}

function CreateCustomTracker({
  cancel,
  teamContext,
  submit,
}: {
  cancel: () => void
  teamContext: any
  submit: (n) => void
}) {
  const [form, setForm] = useState({
    note: 'optional',
    title: '',
    photos: 'optional',
    location: 'optional',
    no_response: false,
    response_type: 'Decimal',
    response_units: 'tons',
    list_options: [],
    list_select: 'single',
  })

  const [listOptions, setListOptions] = useState('')

  const response_type_options = [
    { label: 'Number', db: 'Decimal' },
    { label: 'List', db: 'list' },
    { label: '1 - 10', db: 'onetoten' },
  ]

  const submittable =
    (form.response_type !== 'Decimal' || form.response_units !== '') &&
    (form.response_type !== 'list' || form.list_options.length > 0) &&
    form.title !== ''

  return (
    <div className="CreateCustomTracker">
      <Typography style={{ textDecoration: 'underline' }}>CREATE CUSTOM TRACKER</Typography>
      <Typography>
        {teamContext
          ? 'Will be available for all blocks within ' + teamContext.name
          : 'Will be available for all My Blocks'}
      </Typography>
      <div className="form" style={{ margin: '10px 0' }}>
        <div className="section">
          <div className="label">Title:</div>
          <div className="input">
            <TextField
              style={{ width: '100%' }}
              required
              size="small"
              type="text"
              value={form.title || ''}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
            />
          </div>
        </div>
        <div className="section">
          <div className="label">Response Type:</div>
          <div className="input">
            <FormControl style={{ width: '100%' }} size="small">
              <Select
                style={{ width: '100%' }}
                id="dtselect"
                value={form.response_type}
                onChange={(e) => setForm({ ...form, response_type: e.target.value })}>
                {response_type_options.map((dt) => (
                  <MenuItem key={`iuhewh${dt.db}`} value={dt.db}>
                    {dt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {form.response_type === 'Decimal' && (
          <div className="section">
            <div className="label">Units:</div>
            <div className="input">
              <TextField
                style={{ width: '100%', maxWidth: '200px' }}
                required
                size="small"
                type="text"
                value={form.response_units || ''}
                onChange={(e) => setForm({ ...form, response_units: e.target.value })}
              />
            </div>
          </div>
        )}
        {form.response_type === 'list' && (
          <>
            <div className="section">
              <div className="label">Options:</div>
              <div className="input">
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      style={{ width: '100%', maxWidth: '200px' }}
                      required
                      size="small"
                      type="text"
                      value={listOptions || ''}
                      onChange={(e) => setListOptions(e.target.value)}
                    />
                    <IconButton
                      onClick={() => {
                        if (!!listOptions) {
                          setForm({ ...form, list_options: [...form.list_options, listOptions] })
                          setListOptions('')
                        }
                      }}>
                      <AddIcon />
                    </IconButton>
                  </div>
                  <div style={{ marginTop: '5px', display: 'flex', gap: '3px', flexWrap: 'wrap' }}>
                    {form.list_options.map((lo, i) => (
                      <Chip key={`uttdf${i}`} size="small" label={lo} variant={'outlined'} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="label">Select Single Option or Multiple:</div>
              <div className="input">
                <ToggleButtonGroup
                  value={form.list_select}
                  color="primary"
                  size="small"
                  exclusive
                  onChange={(e, n) => {
                    if (n !== null) {
                      setForm({ ...form, list_select: n })
                    }
                  }}>
                  <ToggleButton value={'single'}>SINGLE</ToggleButton>
                  <ToggleButton value={'multiple'}>MULTIPLE</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          </>
        )}
        <div className="section">
          <div className="label">Add Notes:</div>
          <div className="input">
            <ToggleButtonGroup
              value={form.note}
              color="primary"
              size="small"
              exclusive
              onChange={(e, n) => {
                if (n !== null) {
                  setForm({ ...form, note: n })
                }
              }}>
              <ToggleButton value={DT_OPT} aria-label={DT_OPT}>
                OPTIONAL
              </ToggleButton>
              <ToggleButton value={DT_REQD} aria-label={DT_REQD}>
                REQUIRED
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className="section">
          <div className="label">Add Photos:</div>
          <div className="input">
            <ToggleButtonGroup
              value={form.photos}
              color="primary"
              size="small"
              exclusive
              onChange={(e, n) => {
                if (n !== null) {
                  setForm({ ...form, photos: n })
                }
              }}>
              <ToggleButton value={DT_OPT} aria-label={DT_OPT}>
                OPTIONAL
              </ToggleButton>
              <ToggleButton value={DT_REQD} aria-label={DT_REQD}>
                REQUIRED
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
      <div>
        <Button style={{ marginRight: '20px' }} onClick={() => cancel()}>
          CANCEL
        </Button>
        <Button variant="contained" color="primary" disabled={!submittable} onClick={() => submit(form)}>
          CREATE
        </Button>
      </div>
    </div>
  )
}

function ManageDataTrackers({
  block,
  onSuccess,
  datatrackers,
}: {
  block: Block
  onSuccess: () => void
  datatrackers: any
}) {
  const user = useUser()
  const appState = useAppState()
  const { data: availableDTs, refresh: refreshAvailable } = useSupaGetAvailableDatatrackers(
    user.id,
    appState.teamContext ? appState.teamContext.id : null
  )
  const [tabState, setTabState] = useState(null)
  const [newForm, setNewForm] = useState({ datatracker: null, item_type: null })

  const { data: itemsD } = useSupaGetItems()
  const allItems = itemsD ? itemsD.asArray : null

  const { data: existingRules, refresh: refreshRules } = useSupaGetTeamRules(
    user,
    appState.teamContext ? appState.teamContext.id : null
  )

  const existingDTs = useMemo(() => {
    let x = {}
    if (datatrackers) {
      datatrackers.forEach((dt) => (x = { ...x, [dt.id]: dt }))
    }
    return x
  }, [datatrackers])

  const submitNewRule = async () => {
    let newRule = { ...newForm, owner: user.id, team: appState.teamContext ? appState.teamContext.id : null }
    await addDataTrackerRule(newRule)
    await refreshRules()
  }

  const submitNewCustomDT = async (newDT) => {
    await supabaseClient.from('datatrackers').insert({
      global: false,
      config: newDT,
      owner_id: user.id,
      team_id: appState.teamContext ? appState.teamContext.id : null,
    })
    await refreshAvailable()
    setTabState(null)
  }

  const deleteRule = async (ruleID) => {
    await deleteDataTrackerRule(ruleID)
    await refreshRules()
  }

  if (availableDTs && !!existingDTs) {
    return (
      <div className="ManageDataTrackers">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Data Trackers</Typography>
          <Button onClick={() => setTabState(tabState === null ? 'rules' : null)} variant="contained">
            {tabState === null ? 'MANAGE TEAM RULES' : 'BACK'}
          </Button>
        </div>
        {tabState === null && (
          <>
            <List>
              {availableDTs.map((dt) => (
                <ListItem
                  onClick={async () => {
                    if (!!existingDTs[dt.id]) {
                      await deleteDataTrackerAssignment(dt.id, block.id)
                    } else {
                      await addDataTrackerAssignment(dt.id, block.id)
                    }
                    onSuccess()
                  }}
                  key={`idcieuhd${dt.id}`}>
                  <ListItemButton dense>
                    <ListItemIcon>
                      <Checkbox size="small" edge="start" checked={!!existingDTs[dt.id]} tabIndex={-1} disableRipple />
                    </ListItemIcon>
                    <ListItemText primary={dt.config.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Button onClick={() => setTabState('addcustom')}>ADD CUSTOM TRACKER</Button>
          </>
        )}
        {tabState === 'rules' && (
          <div>
            <Typography style={{ textDecoration: 'underline' }}>
              {appState.teamContext ? appState.teamContext.name + ' - Team Rules' : 'My Block Rules'}
            </Typography>
            <div className="ruleList" style={{ marginTop: '10px', maxHeight: '200px', overflow: 'auto' }}>
              {existingRules &&
                existingRules.map((rule) => (
                  <div style={{ display: 'flex', alignItems: 'center' }} key={`uehdjs${rule.id}`}>
                    <IconButton size="small" onClick={() => deleteRule(rule.id)}>
                      <DeleteForeverIcon style={{ color: 'red' }} />
                    </IconButton>
                    {rule.datatracker.config.title}
                    {rule.item ? ', ' + rule.item.name : ', All items'}
                  </div>
                ))}
            </div>
            <Typography style={{ textDecoration: 'underline', marginTop: '10px' }}>Add New</Typography>
            <Typography>
              Choose a Data Tracker to apply to all blocks within this team. Select a Default Item to only apply the
              tracker to certain item types. Leave blank to apply tracker to all blocks.
            </Typography>
            <div style={{ marginTop: '10px' }}>
              <div className="newForm" style={{ marginBottom: '10px' }}>
                <div>
                  <FormControl sx={{ minWidth: 300 }} size="small">
                    <InputLabel id="dtselectlabel">Data Tracker</InputLabel>
                    <Select
                      labelId="dtselectlabel"
                      id="dtselect"
                      value={newForm.datatracker ? newForm.datatracker : ''}
                      label="Data Tracker"
                      onChange={(e) =>
                        setNewForm({ ...newForm, datatracker: e.target.value !== '' ? e.target.value : null })
                      }>
                      {availableDTs &&
                        availableDTs.map((dt) => (
                          <MenuItem key={`iuhewh${dt.id}`} value={dt.id}>
                            {dt.config.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <FormControl sx={{ minWidth: 300 }} size="small">
                    <InputLabel id="itemselectlabel">Specific Default Item (optional)</InputLabel>
                    <Select
                      labelId="itemselectlabel"
                      id="itemselect"
                      value={newForm.item_type ? newForm.item_type : ''}
                      label="Specific Default Item (optional)"
                      onChange={(e) =>
                        setNewForm({ ...newForm, item_type: e.target.value !== '' ? e.target.value : null })
                      }>
                      <MenuItem value="">
                        <em>All Item Types</em>
                      </MenuItem>
                      {allItems &&
                        allItems.map((i) => (
                          <MenuItem key={`jouwi${i.id}`} value={i.id}>
                            {i.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <Button onClick={() => submitNewRule()} variant="contained" disabled={!!!newForm.datatracker}>
                CREATE NEW
              </Button>
            </div>
          </div>
        )}
        {tabState === 'addcustom' && (
          <CreateCustomTracker
            cancel={() => setTabState(null)}
            teamContext={appState.teamContext}
            submit={(newDT) => submitNewCustomDT(newDT)}
          />
        )}
      </div>
    )
  } else {
    return null
  }
}

function CreatePlanting({
  plantingP,
  block,
  onSubmit,
  update,
  onSuccess,
}: {
  plantingP: Planting
  block: Block
  onSubmit: () => void
  update?: boolean
  onSuccess: () => void
}) {
  const [planting, setPlanting] = useState(plantingP)
  const [existingPhotos, setExistingPhotos] = useState(null)

  const user = useUser()

  const { data: itemsD } = useSupaGetItems()
  const items = itemsD ? itemsD.asArray : null

  const submit = async (p) => {
    if (!p.item) {
      return
    }

    await createUpdatePlanting({ ...p, block }, user)

    onSuccess()

    onSubmit()
  }

  const getExistingPhotos = useCallback(async () => {
    supabaseClient
      .from('datatracker_responses')
      .select(`photos`)
      .eq('block_id', block.id)
      .then((res) => {
        if (res.data) {
          const photos = res.data.map((r) => r.photos).flat()
          setExistingPhotos(photos)
        }
      })
  }, [block.id])

  const onImageChange = async (imageList) => {
    // bug - this may create orphaned images if not saved
    const urls = []
    for (let index = 0; index < imageList.length; index++) {
      const image = imageList[index]
      const { type, name } = image.file
      const ext = name.split('.').pop()

      const timestamp = new Date().getTime()

      const filename = `${user.id}/${user.id}${timestamp.toString()}${index}.${ext}`

      await supabaseClient.storage
        .from('user_photos')
        .upload(filename, decode(image.data_url.split('base64,').pop()), {
          contentType: type,
        })
        .then((res) => {
          if (res.data) {
            urls.push(res.data.path)
          }
        })
    }

    setPlanting({
      ...planting,
      photos: [
        ...planting.photos,
        ...urls.map((u) => ({ url: u, market_sort: 1000000, planting_id: update ? null : planting.id })),
      ],
    })
  }

  const addPhotoToState = (photo) => {
    if (planting.photos.map((p) => p.url).includes(photo.url)) {
      return
    }

    setPlanting({ ...planting, photos: [...planting.photos, photo] })
  }

  const removePhotoFromState = (url) => {
    setPlanting({ ...planting, photos: planting.photos.filter((p) => p.url !== url) })
  }

  const changeSort = (url: string, sortChange: number) => {
    const index = planting.photos.map((p) => p.url).indexOf(url)
    if (index < 0 || index + sortChange >= planting.photos.length || index + sortChange < 0) {
      return
    }
    const newPhotos = [...planting.photos]
    const temp = newPhotos[index + sortChange]
    newPhotos[index + sortChange] = newPhotos[index]
    newPhotos[index] = temp
    setPlanting({ ...planting, photos: newPhotos })
  }

  useEffect(() => {
    getExistingPhotos()
  }, [getExistingPhotos])

  return (
    <div>
      <Typography variant="h6">{update ? 'Edit' : 'New'} Planting</Typography>
      <div style={{ margin: '20px 0' }}>
        <TextField required label="Block" size="small" type="text" value={block.name} fullWidth disabled={true} />
      </div>
      <div style={{ margin: '20px 0' }}>
        <FormControl required fullWidth>
          <InputLabel id="newplantingItem">Item</InputLabel>
          <Select
            label="Item"
            id="newplantingItemS"
            labelId="newplantingItem"
            required
            value={items && planting.item ? planting.item.id : ''}
            onChange={(e) => setPlanting({ ...planting, item: { id: Number(e.target.value) } })}>
            {items &&
              items.map((item) => (
                <MenuItem key={`gewr${item.id}`} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ margin: '20px 0' }}>
        <TextField
          multiline
          label="Description"
          size="small"
          type="text"
          value={planting.description || ''}
          onChange={(e) => setPlanting({ ...planting, description: e.target.value })}
          fullWidth
        />
      </div>
      <div style={{ margin: '20px 0', display: 'flex', gap: '20px' }}>
        <TextField
          id="sdate"
          label="Approximate Planting Date"
          type="date"
          value={planting.starting_date ? planting.starting_date : ''}
          onChange={(e) => setPlanting({ ...planting, starting_date: e.target.value })}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="edate"
          label="Approximate Harvest Date"
          type="date"
          value={planting.ending_date ? planting.ending_date : ''}
          onChange={(e) => setPlanting({ ...planting, ending_date: e.target.value })}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div style={{ margin: '20px 0', display: 'flex', gap: '10px' }}>
        <TextField
          style={{ flex: 1 }}
          label="Quantity Available/Expected"
          size="small"
          type="number"
          value={planting.quantity || 0}
          onChange={(e) => setPlanting({ ...planting, quantity: Number(e.target.value) })}
        />
        <TextField style={{ flex: 1 }} label="Units" size="small" type="text" value={'ton'} disabled fullWidth />
      </div>
      <div>
        <ToggleButtonGroup
          value={planting.transaction_side}
          color="primary"
          size="small"
          exclusive
          onChange={(e, n) => {
            setPlanting({ ...planting, transaction_side: n })
          }}>
          <ToggleButton value={'sell'}>SELLING</ToggleButton>
          <ToggleButton value={'buy'}>BUYING</ToggleButton>
        </ToggleButtonGroup>
      </div>
      {planting.transaction_side === 'sell' && (
        <>
          <div style={{ margin: '20px 0' }}>
            <TextField
              label="For Sale By Display Name"
              size="small"
              type="text"
              value={planting.display_name || ''}
              fullWidth
              onChange={(e) => setPlanting({ ...planting, display_name: e.target.value })}
            />
          </div>
          <div style={{ margin: '20px 0', display: 'flex', gap: '20px' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!planting.onMarket}
                    onChange={(e) => setPlanting({ ...planting, onMarket: !!e.target.checked })}
                  />
                }
                label="On Market"
              />
            </FormGroup>
            <TextField
              id="price"
              label={`Price/${planting.units || 'ton'}`}
              type="number"
              value={planting.price ? planting.price : ''}
              onChange={(e) => setPlanting({ ...planting, price: Number(e.target.value) })}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </div>
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Photos</div>
            <div className="managePhotos" style={{ marginBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
                {planting.photos?.map((photo) => (
                  <div
                    key={photo.url}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                    }}>
                    <img
                      alt=""
                      style={{ maxHeight: '60px', maxWidth: '60px' }}
                      src={`https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/user_photos/${photo.url}`}
                    />
                    <div>
                      <IconButton size="small" onClick={() => changeSort(photo.url, -1)}>
                        <ArrowBack />
                      </IconButton>
                      <IconButton size="small" onClick={() => removePhotoFromState(photo.url)}>
                        <DeleteForeverIcon />
                      </IconButton>
                      <IconButton size="small" onClick={() => changeSort(photo.url, 1)}>
                        <ArrowForward />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="addPhotos">
              <div>Add Photos</div>
              <div className="existing">
                <div style={{ textDecoration: 'underline' }}>Use Data Tracker Responses</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
                  {existingPhotos
                    ?.filter((p) => !planting.photos.map((p) => p.url).includes(p))
                    .map((p) => (
                      <div
                        key={p}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '5px',
                        }}>
                        <img
                          alt=""
                          style={{ maxHeight: '60px', maxWidth: '60px' }}
                          src={`https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/user_photos/${p}`}
                        />
                        <IconButton
                          size="small"
                          onClick={() =>
                            addPhotoToState({ url: p, market_sort: 1000000, planting_id: update ? null : planting.id })
                          }>
                          <AddBoxIcon />
                        </IconButton>
                      </div>
                    ))}
                </div>
              </div>
              <div className="upload">
                <div style={{ textDecoration: 'underline' }}>Upload New Photos</div>
                <div>
                  <ImageUploading multiple value={[]} onChange={onImageChange} maxNumber={5} dataURLKey="data_url">
                    {({ onImageUpload, isDragging, dragProps }) => (
                      <div className="upload__image-wrapper">
                        <button
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}>
                          Click or Drop here
                        </button>
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div style={{ margin: '20px 0', display: 'flex', gap: '20px' }}>
        <Button variant="outlined" onClick={() => onSubmit()}>
          CANCEL
        </Button>
        <Button variant="contained" onClick={() => submit(planting)}>
          {update ? 'SAVE' : 'CREATE'}
        </Button>
      </div>
    </div>
  )
}

export default observer(BlockDetail)
