import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import { useSupaGetItems } from '../data/items'
import '../styles/Filters.css'
import { TreeSelect } from 'antd'
import { observer } from 'mobx-react-lite'
import { useAppState } from '../data/app_state'
import { useSupaGetBoundaries } from '../data/boundaries'
import { useLocation } from 'react-router-dom'

const { SHOW_PARENT } = TreeSelect

function TreeS({ treeData, selectedValues, onChangeS }) {
  const onChange = (newValue: number[]) => {
    onChangeS(newValue)
  }

  const tProps = {
    treeData,
    fieldNames: { label: 'name', value: 'id' },
    value: selectedValues,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Select Crop Types',
    allowClear: true,
    treeNodeFilterProp: 'name',
    style: { width: '100%' },
    dropdownStyle: { zIndex: 2000 },
  }

  return <TreeSelect {...tProps} />
}

function TreeSS({ treeData, selectedValues, onChangeS }) {
  const onChange = (newValue: number[]) => {
    onChangeS(newValue)
  }

  const tProps = {
    treeData,
    fieldNames: { label: 'name', value: 'id' },
    value: selectedValues,
    showCheckedStrategy: SHOW_PARENT,
    onChange,
    treeCheckable: true,
    placeholder: 'Select Boundaries',
    allowClear: true,
    showSearch: true,
    treeNodeFilterProp: 'name',
    style: { width: '100%' },
    dropdownStyle: { zIndex: 2000 },
  }

  return <TreeSelect {...tProps} />
}

function Filters() {
  const appState = useAppState()
  const vals = appState.filterState

  const location = useLocation()

  const valChange = (key, newState) => {
    const newVals = { ...vals, [key]: newState }
    appState.updateFilter(newVals)
  }

  const { data: itemsD } = useSupaGetItems()
  const itemsTree = itemsD ? itemsD.asTree : null

  const { data: boundariesD } = useSupaGetBoundaries()
  const boundryTree = boundariesD ? boundariesD.asTree : null
  const boundryArr = boundariesD ? boundariesD.asArray : null

  const boundaryChange = (key, newState) => {
    const x = boundryArr.filter((b) => newState.includes(b.id))
    const newVals = { ...vals, [key]: x }
    appState.updateFilter(newVals)
  }

  return (
    <div className="Filters">
      {location.pathname.startsWith('/market') && (
        <div className="year">
          <div className="label">
            <Typography id="filters-year" gutterBottom>
              Harvest Year
            </Typography>
            <Typography id="filters-year" gutterBottom>
              {Math.min(...vals.year) === Math.max(...vals.year)
                ? Math.min(...vals.year)
                : Math.min(...vals.year) + ' - ' + Math.max(...vals.year)}
            </Typography>
          </div>
          <div className="input">
            <Slider
              aria-labelledby="filters-year"
              min={2023}
              max={2030}
              value={vals.year}
              onChange={(x, y, z) => valChange('year', y)}
              valueLabelDisplay="auto"
              step={1}
              marks={true}
              disableSwap={true}
            />
          </div>
        </div>
      )}
      <div className="items">
        <div className="label">
          <Typography id="filters-items" gutterBottom>
            Items
          </Typography>
        </div>
        <div className="input">
          <TreeS treeData={itemsTree} selectedValues={vals.items} onChangeS={(x) => valChange('items', x)} />
        </div>
      </div>
      <div className="items">
        <div className="label">
          <Typography id="filters-items" gutterBottom>
            Boundaries
          </Typography>
        </div>
        <div className="input">
          <TreeSS
            treeData={boundryTree}
            selectedValues={vals.boundaries.map((b) => b.id)}
            onChangeS={(x) => boundaryChange('boundaries', x)}
          />
        </div>
      </div>
      {/* <div className="boundaries" style={{ flex: 1 }}>
        <div className='input'>
        <Autocomplete
          multiple
          size='small'
          id="checkboxes-tags-demo"
          options={boundaries || []}
          disableCloseOnSelect
          onChange={(x, y, z) => valChange('boundaries', y)}
          value={vals.boundaries}
          getOptionLabel={(option: any) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option: any, { selected }) => (
            <li {...props} key={`fhciqewnd${option.id}`}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField {...params} label="Regions" placeholder='' />
          )}
        />
        </div>
      </div> */}
    </div>
  )
}

export default observer(Filters)
