import { Status, Wrapper } from '@googlemaps/react-wrapper'
import { ReactElement, useRef, useState, useCallback, useEffect, useMemo } from 'react'
import { Block } from '../helper/types'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { observer } from 'mobx-react-lite'
import { useAppState } from '../data/app_state'
import { GeoJSON } from 'geojson'

const BOUNDARY_DATASETID = 'af69d53c-ba4f-4c3f-aed1-949b5a434312'
// const GLOBALS_DATASETID = '1c51f909-ef1b-476b-a8b6-19ab5fea7439'
const MAP_ID = '1bab2860ec54a14a'

const render = (status: Status): ReactElement<any> => {
  if (status === Status.FAILURE) return <h3>{status} ...</h3>
  return <div>LOADING</div>
}

interface MapFocusState {
  blockFocused: boolean
}

const buildMarkerContent = (block) => {
  const { default_item, plantings } = block
  let itemToDisplay = null
  let priceToDisplay = null
  const today = new Date()
  const relevantPlantings =
    plantings &&
    plantings.filter((p) => {
      const ed = new Date(p.ending_date)
      return ed >= today
    })

  if (!!relevantPlantings && relevantPlantings.length > 0) {
    // todo - this does not handle timezones correctly
    const earliestPlanting = relevantPlantings.sort(
      (a, b) => Date.parse(a.starting_date) - Date.parse(b.starting_date)
    )[0]
    itemToDisplay = earliestPlanting.item
    priceToDisplay = earliestPlanting.price
  } else if (default_item) {
    itemToDisplay = default_item
  }

  const content = document.createElement('div')
  content.classList.add('blockMarker')

  if (!!itemToDisplay) {
    content.innerHTML = `
      <div>
        <div style="text-align: center">
          <img
            style="width:20px;height:20px"
            src="https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/site-assets/icons/${
              itemToDisplay.icon_name
            }.png"
            alt=${itemToDisplay.icon_name}
          />
        </div>
        ${!!priceToDisplay ? `<div style="text-align: center">$${priceToDisplay}</div>` : ''}
      </div>
    `
  }

  return content
}

const setDatasetStyle = (params, state) => {
  const datasetFeature = params.feature

  if (state.map((x) => x.id).includes(Number(datasetFeature.datasetAttributes['id']))) {
    return {
      strokeColor: 'blue',
      strokeWeight: 2,
      strokeOpacity: 1,
      fillColor: 'blue',
      fillOpacity: 0.1,
    }
  }
  return null
}

// const setGlobalDatasetStyle = (params) => {
//   const datasetFeature = params.feature
//   const color = datasetFeature.datasetAttributes['color']

//   return {
//     strokeColor: color ? color : 'black',
//     strokeWeight: 2,
//     strokeOpacity: 1,
//     fillColor: color ? color : 'black',
//     fillOpacity: 0.4,
//   }
// }

// function handleGlobalDatasetClick(e) {
//   console.log('click', e)
// }

function Map({
  center,
  blocks,
  focusedBlock,
  onFeatureClick,
}: {
  center: google.maps.LatLngLiteral
  blocks: Array<Block>
  onFeatureClick: (id: any, is_global: boolean) => void
  focusedBlock?: Block
}) {
  const appState = useAppState()

  const mapRef = useRef(null)
  const searchRef = useRef(null)
  const markerClusterRef = useRef(null)
  const infowindow = useMemo(() => new google.maps.InfoWindow(), [])

  const [mapFocusState, setMapFocusState] = useState<MapFocusState>({ blockFocused: false })

  const [map, setMap] = useState<google.maps.Map>(null)
  const [searchBox, setSearchBox] = useState(null)

  const [searchMarkers, setSearchMarkers] = useState<google.maps.Marker[]>([])
  const searchMarkersRef = useRef<google.maps.Marker[]>()
  searchMarkersRef.current = searchMarkers

  const [blockMarkers, setBlockMarkers] = useState<google.maps.Marker[]>([])
  const blockMarkersRef = useRef<google.maps.Marker[]>()
  blockMarkersRef.current = blockMarkers

  const addBlocksToMap = useCallback(
    async (blocks, map) => {
      console.log('refreshing blocks on map')

      const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary

      if (blockMarkersRef.current && blockMarkersRef.current.length > 0) {
        for (let i = 0; i < blockMarkersRef.current.length; i++) {
          blockMarkersRef.current[i].setMap(null)
        }
      }

      if (markerClusterRef.current) {
        markerClusterRef.current.clearMarkers()
        setBlockMarkers([])
      }

      const markersx = []
      blocks.forEach((block) => {
        let { geometry, type, properties } = block.geo
        const { default_item, name, parcel } = block

        map.data.addGeoJson(
          {
            geometry,
            type,
            properties: {
              ...properties,
              id: block.id,
              focused: focusedBlock && focusedBlock.id === block.id ? 'true' : null,
              is_global: block.is_global,
              color: block.is_global ? (block.color ? block.color : 'white') : 'black',
              default_item,
              name,
              owner: parcel ? parcel.owner : null,
              parcel_id: parcel ? parcel.parcel_id : null,
            },
          },
          { idPropertyName: 'id' }
        )

        if (!!!block.is_global) {
          let marker = new AdvancedMarkerElement({
            map,
            position: { lat: geometry.coordinates[0][0][1], lng: geometry.coordinates[0][0][0] },
            content: buildMarkerContent(block),
            zIndex: 101,
          })

          marker.addListener('gmp-click', () => {
            onFeatureClick(block.id, !!block.is_global)
          })

          markersx.push(marker)
        }
      })

      if (!markerClusterRef.current) {
        markerClusterRef.current = new MarkerClusterer({ map, markers: markersx })
      } else {
        markerClusterRef.current.addMarkers(markersx)
      }

      if (markersx && markersx.length > 0) {
        setBlockMarkers(markersx)
      }
    },
    [onFeatureClick, focusedBlock]
  )

  useEffect(() => {
    if (mapRef.current && map) {
      const datasetLayer = map.getDatasetFeatureLayer(BOUNDARY_DATASETID)
      if (appState.filterState.boundaries.length === 0) {
        datasetLayer.style = null
      } else {
        datasetLayer.style = (params) => setDatasetStyle(params, appState.filterState.boundaries)

        let bounds = new google.maps.LatLngBounds()
        appState.filterState.boundaries
          .map((b) => b.bbox)
          .forEach((feat) => {
            if (!!feat && !!feat.coordinates) {
              feat.coordinates[0].forEach((ll) => bounds.extend({ lat: ll[1], lng: ll[0] }))
              map.fitBounds(bounds, 50)
            }
          })
      }
    }
  }, [map, appState.filterState.boundaries])

  // creates map
  useEffect(() => {
    function handleFeatureAdd(e: google.maps.Data.AddFeatureEvent) {
      if (!!e.feature.getProperty('new')) {
        // hack to filter creating existing blocks
        e.feature.removeProperty('new')
        e.feature.toGeoJson((a: GeoJSON) => {
          if (appState.createBlock) {
            appState.createBlock.geo = a
          }
        })
      }
    }

    function handleFeatureClick(e: google.maps.Data.AddFeatureEvent) {
      onFeatureClick(e.feature.getId(), !!e.feature.getProperty('is_global'))
    }

    if (map) {
      return
    }

    if (!mapRef.current) {
      return
    }

    let m = new google.maps.Map(mapRef.current, {
      mapId: MAP_ID,
      zoom: 9,
      center,
      clickableIcons: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      fullscreenControl: false,
      controlSize: 20,
      panControl: true,
      rotateControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_RIGHT,
      },
    })

    const x = new google.maps.Data({
      map: m,
      featureFactory: function (a: google.maps.Data.Geometry): google.maps.Data.Feature {
        return new google.maps.Data.Feature({ geometry: a, properties: { new: true } })
      },
    })
    m.data = x

    const dmode = appState.isInCreationMode ? 'Polygon' : null
    m.data.setDrawingMode(dmode)

    m.data.setStyle(function (feature) {
      var f = feature.getProperty('focused')
      var g = feature.getProperty('is_global')
      var c = feature.getProperty('color')

      const isFocused = f !== 'undefined' && f && f !== ''
      const isGlobal = g !== 'undefined' && g && g !== ''
      const color = c !== 'undefined' && c && c !== '' ? c : 'white'

      return {
        fillColor: isFocused ? 'blue' : isGlobal ? color : 'black',
        fillOpacity: isGlobal ? 0.4 : 0.2,
        strokeWeight: isGlobal ? 1 : 3,
        strokeOpacity: 1,
        strokeColor: isFocused ? 'blue' : isGlobal ? color : 'black',
        zIndex: isFocused ? 1000 : isGlobal ? 10 : 11,
      }
    })

    m.data.addListener('addfeature', handleFeatureAdd)
    m.data.addListener('click', handleFeatureClick)
    m.data.addListener('mouseover', (e) => {
      const isGlobal = e.feature.getProperty('is_global')
      const di = e.feature.getProperty('default_item')
      const name = e.feature.getProperty('name')
      const owner = e.feature.getProperty('owner')
      // const parcel_id = e.feature.getProperty('parcel_id')

      if (isGlobal) {
        infowindow.setContent(`
        <div>
          <div style="text-align: center">
            ${di ? di.name : 'Unknown'}
          </div>
          ${owner ? `<div style="text-align: center">${owner}</div>` : ''}
        </div>`)
      } else {
        infowindow.setContent(`<div style="text-align: center">${name ? name : 'Unknown'}</div>`)
      }

      infowindow.setPosition(e.feature.getGeometry().getAt(0).getAt(0))
      infowindow.open({ map: m, shouldFocus: false })
    })
    m.data.addListener('mouseout', () => {
      infowindow.close()
    })

    m.addListener('bounds_changed', (x) => {
      appState.updateMapBounds(m.getBounds())
    })

    const datasetLayer = m.getDatasetFeatureLayer(BOUNDARY_DATASETID)
    if (appState.filterState.boundaries.length === 0) {
      datasetLayer.style = null
    } else {
      datasetLayer.style = (params) => setDatasetStyle(params, appState.filterState.boundaries)
    }

    // let datasetGlobalLayer = m.getDatasetFeatureLayer(GLOBALS_DATASETID)
    // datasetGlobalLayer.style = (params) => setGlobalDatasetStyle(params)
    // datasetGlobalLayer.addListener('click', handleGlobalDatasetClick)

    setMap(m)
  }, [
    appState.createBlock,
    appState.isInCreationMode,
    center,
    map,
    onFeatureClick,
    appState.filterState.boundaries,
    appState,
    infowindow,
  ])

  useEffect(() => {
    if (mapRef.current && map) {
      google.maps.event.clearListeners(map.data, 'click')
      map.data.addListener('click', (c: google.maps.Data.AddFeatureEvent) =>
        onFeatureClick(c.feature.getId(), !!c.feature.getProperty('is_global'))
      )
    }
  }, [map, onFeatureClick])

  useEffect(() => {
    if (mapRef.current && map && center) {
      map.setCenter(center)
    }
  }, [map, center])

  // adds blocks to map, clears unsaved drawn block
  useEffect(() => {
    if (mapRef.current && map && blocks) {
      map.data.forEach((f) => {
        if (f.getProperty('new')) {
          map.data.remove(f)
        }
      })

      if (blocks) {
        addBlocksToMap(blocks, map)
      }
    }
  }, [map, blocks, addBlocksToMap])

  // moves map window to focused block
  useEffect(() => {
    if (mapRef.current && map && map.data) {
      if (focusedBlock && map.data.getFeatureById(focusedBlock.id)) {
        let bounds = new google.maps.LatLngBounds()
        map.data
          .getFeatureById(focusedBlock.id)
          .getGeometry()
          .forEachLatLng((ll) => bounds.extend(ll))

        // map.data.overrideStyle(map.data.getFeatureById(focusedBlock.id), {fillColor: 'red'})
        map.fitBounds(bounds, 50)
        if (!mapFocusState.blockFocused) {
          setMapFocusState({ ...mapFocusState, blockFocused: true })
        }
      } else {
        if (mapFocusState.blockFocused) {
          // now we should go back to default
          if (center) {
            map.setCenter(center)
            map.setZoom(9)
          }
          setMapFocusState({ ...mapFocusState, blockFocused: false })
        }
      }
    }
  }, [focusedBlock, map, mapFocusState, center])

  // changes drawing modes, removes
  useEffect(() => {
    if (mapRef.current && map) {
      const dmode = appState.isInCreationMode ? 'Polygon' : null
      map.data.setDrawingMode(dmode)

      // hack to remove unsaved blocks when shifting draw modes
      let numFeaturesOnMap = 0
      map.data.forEach((f) => numFeaturesOnMap++)
      if (numFeaturesOnMap > 0) {
        if (blocks) {
          if (blocks.length !== numFeaturesOnMap) {
            map.data.forEach((f) => {
              if (f.getProperty('new') || !appState.isInCreationMode) {
                map.data.remove(f)
              }
            })
            addBlocksToMap(blocks, map) // why this necessary?
          }
        } else {
          map.data.forEach((f) => {
            map.data.remove(f)
          })
        }
      }
    }
  }, [map, appState.isInCreationMode, blocks, addBlocksToMap])

  useEffect(() => {
    // stole from https://developers.google.com/maps/documentation/javascript/examples/places-searchbox#maps_places_searchbox-typescript
    if (!searchRef.current || !map || searchBox) {
      return
    }

    let mapSearchBox = new google.maps.places.SearchBox(searchRef.current)
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(searchRef.current)
    searchRef.current.visibility = 'visible'
    map.addListener('bounds_changed', () => {
      mapSearchBox.setBounds(map.getBounds() as google.maps.LatLngBounds)
    })
    mapSearchBox.addListener('places_changed', () => {
      const places = mapSearchBox.getPlaces()

      if (places.length === 0) {
        return
      }

      searchMarkersRef.current.forEach((marker) => {
        marker.setMap(null)
      })

      const bounds = new google.maps.LatLngBounds()
      let markersTemp: google.maps.Marker[] = []
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log('Returned place contains no geometry')
          return
        }

        // Create a marker for each place
        markersTemp.push(
          new google.maps.Marker({
            map,
            // icon,
            title: place.name,
            position: place.geometry.location,
          })
        )

        if (place.geometry.viewport) {
          // Only geocodes have viewport
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }
      })

      setSearchMarkers(markersTemp)
      map.fitBounds(bounds)
    })

    setSearchBox(mapSearchBox)
  }, [searchBox, map])

  return (
    <>
      <input
        ref={searchRef}
        placeholder="Search the map"
        style={{ position: 'absolute', visibility: 'hidden' }}
        type="text"
      />
      <div ref={mapRef} id="map" />
    </>
  )
}

const ObserverMap = observer(Map)

type MapContainerProps = {
  blocks?: Block[]
  onFeatureClick: (id: any, is_global: boolean) => void
  focusedBlock?: Block
}

function MapContainer({ blocks, onFeatureClick, focusedBlock }: MapContainerProps) {
  const [center, setCenter] = useState(null)

  useEffect(() => {
    const defaultCenter = { lat: -33.872, lng: 151.252 }

    if (center) return

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        },
        () => setCenter(defaultCenter)
      )
    } else {
      setCenter(defaultCenter)
    }
  }, [center])

  return (
    <Wrapper
      version="beta"
      apiKey="AIzaSyC1t5JCQTXiaDyqqOJJobV5iJWjeoXaNvQ"
      render={render}
      libraries={['places', 'geometry']}>
      <ObserverMap center={center} blocks={blocks} focusedBlock={focusedBlock} onFeatureClick={onFeatureClick} />
    </Wrapper>
  )
}

export default observer(MapContainer)
