import { useSupaGet } from './supabaseHelpers'
import { supabaseClient } from '../config/supabase'

const useSupaGetItems = () => {
  const req = () => {
    return supabaseClient
      .from('items')
      .select(`*`)
      .order('sort', { ascending: true })
      .order('name', { ascending: true })
  }

  const x = useSupaGet(req, (itemsarr) => {
    var root = itemsarr.reduce(
      (children, item) => {
        children(item.tree_path.replace(/(^|\.)\w+$/g, '')).push({
          ...item,
          children: children(item.tree_path),
        })

        return children
      },
      function (key) {
        return this[key] || (this[key] = [])
      }.bind({})
    )('')

    return { asArray: itemsarr, asTree: root }
  })

  return x
}

export { useSupaGetItems }
