import { useState } from 'react'
import { Route, Switch as RRSwitch, useLocation, useHistory } from 'react-router-dom'
import MapContainer from '../components/Map'
import MarketTab from '../components/MarketTab'
import { observer } from 'mobx-react-lite'
import MyBlocksTab from '../components/MyBlocksTab'
import BlockDetailTab from '../components/BlockDetailTab'
// import GlobalLoader from '../components/GlobalLoader'

function MapPage() {
  const navigate = useHistory()
  const location = useLocation()

  const [blocksData, setBlocksData] = useState(null)
  const [globalBlocksData] = useState([])
  const [focusedBlock, setFocusedBlock] = useState(null)

  const clearMap = () => {
    setBlocksData(null)
    setFocusedBlock(null)
  }

  const onFeatureClicked = (id: any, is_global: boolean) => {
    if (location.pathname === '/' || location.pathname.startsWith('/blocks') || is_global) {
      // const id = feature.getId()
      navigate.push(`/blocks/${id}`)
    } else if (location.pathname.startsWith('/market')) {
      // const id = feature.getId()
      navigate.push(`/market/${id}`)
    }
  }

  const blocksFlat =
    blocksData &&
    (blocksData.grouped ? [...blocksData.grouped.map((b) => b.blocks).flat(), ...blocksData.ungrouped] : blocksData)

  const combinedBlocksFlat = blocksFlat && globalBlocksData && [...blocksFlat, ...globalBlocksData]

  return (
    <div className="MapPage">
      {/* <GlobalLoader setGlobalBlocksData={(bd) => setGlobalBlocksData(bd)} /> */}
      <div className="mapCont">
        <MapContainer
          blocks={combinedBlocksFlat}
          onFeatureClick={(id, is_global) => onFeatureClicked(id, is_global)}
          focusedBlock={focusedBlock}
        />
      </div>
      <div className="tab">
        <RRSwitch>
          <Route path="/" exact>
            <MyBlocksTab setBlocksData={(bd) => setBlocksData(bd)} clearMap={() => clearMap()} />
          </Route>
          <Route path="/market">
            <MarketTab setBlocksData={setBlocksData} setFocusedBlock={(fb) => setFocusedBlock(fb)} />
          </Route>
          <Route path="/blocks/:blockId">
            <BlockDetailTab
              blocks={blocksFlat}
              setBlockData={(b) => setBlocksData(b)}
              setFocusedBlock={(fb) => setFocusedBlock(fb)}
              clearMap={() => clearMap()}
            />
          </Route>
        </RRSwitch>
      </div>
    </div>
  )
}

export default observer(MapPage)
