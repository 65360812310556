import Fab from '@mui/material/Fab'
import Filters from './Filters'
import { Route } from 'react-router-dom'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useState } from 'react'
import useWindowDimensions from '../helper/windowDimensions'
import { Drawer } from '@mui/material'

function ActionBar() {
  const [fabOpen, setFabState] = useState(false)
  const { width } = useWindowDimensions()

  const displayMobile = width <= 600

  return (
    <Route path={['/', '/market']} exact>
      {displayMobile ? (
        <Drawer anchor={'top'} open={fabOpen} onClose={() => setFabState(false)}>
          <div className={`actionBar ${displayMobile ? 'displayMobile' : 'desktop'}`}>
            <div style={{ textAlign: 'center' }}>FILTERS</div>
            <Filters />
          </div>
        </Drawer>
      ) : (
        <div className={`actionBar ${displayMobile ? 'displayMobile' : 'desktop'}`}>
          <Filters />
        </div>
      )}
      <Fab onClick={() => setFabState(!fabOpen)} classes={{ root: 'filterfab' }} size="small" color="primary">
        <FilterListIcon />
      </Fab>
    </Route>
  )
}

export default ActionBar
