import { Card, IconButton } from '@mui/material'
import { useUser } from '@supabase/auth-helpers-react'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppState } from '../data/app_state'
import CreateBlock from './CreateBlock'
import { supabaseClient } from '../config/supabase'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { AuthStatus } from '../pages/Auth'
import { useHotkeys } from 'react-hotkeys-hook'

const transformData = (data) => {
  let groupBlocks = {}
  let groupData = {}
  let ungrouped = []
  data.forEach((block) => {
    if (block.block_groups) {
      let x = []
      if (groupBlocks[block.block_groups.id]) {
        x = groupBlocks[block.block_groups.id]
      }
      x.push(block)
      groupBlocks[block.block_groups.id] = x
      groupData[block.block_groups.id] = block.block_groups
    } else {
      ungrouped.push(block)
    }
  })
  let grouped = []
  for (const [key, value] of Object.entries(groupData)) {
    grouped = [...grouped, { ...(value as Record<string, unknown>), blocks: groupBlocks[key] }]
  }
  return { grouped, ungrouped }
}

function RenderBlock({ block, navigate }) {
  return (
    <Card onClick={() => navigate.push(`blocks/${block.id}`)} className="blockNode" key={`wefwef${block.id}`}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
        <div>{block.name}</div>
        <div>
          {block.default_item && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{block.default_item.name}</span>
              <img
                style={{ width: '20px', height: '20px' }}
                src={
                  'https://wfwepocffbytbzcsbyxv.supabase.co/storage/v1/object/public/site-assets/icons/' +
                  block.default_item.icon_name +
                  '.png'
                }
                alt={block.default_item.icon_name}
              />
            </div>
          )}
          {block.is_global ? '*****' : ''}
        </div>
      </div>
    </Card>
  )
}

function MyBlocksTab({ setBlocksData, clearMap }: { setBlocksData: (x: any) => void; clearMap: () => void }) {
  const user = useUser()
  const appState = useAppState()
  const filters = appState.filterState
  const boundaryFilter = filters.boundaries
  const itemFilter = filters.items
  const navigate = useHistory()
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const admin = location.search.includes('admin')

  const [bd, setBd] = useState(null)

  useHotkeys('a', () => {
    if (appState.isInCreationMode) {
      appState.endBlockCreation()
    } else {
      onCreateClick()
    }
  })

  const onCreateClick = () => {
    appState.beginBlockCreation(!!admin)
  }

  const getMarket = useCallback(async () => {
    setLoading(true)
    const { data } = await supabaseClient.rpc('get_my_blocks', {
      u: user.id,
      boundary_ids: boundaryFilter && boundaryFilter.length > 0 ? boundaryFilter.map((bf) => bf.id) : [],
      item_ids: itemFilter && itemFilter.length > 0 ? itemFilter : [],
      team_id_p: appState.teamContext ? appState.teamContext.id : null,
    })
    setBd(transformData(data))
    setLoading(false)
  }, [user, boundaryFilter, itemFilter, appState.teamContext])

  useEffect(() => {
    if (!!user) {
      getMarket()
    }
  }, [boundaryFilter, user, getMarket])

  useEffect(() => {
    return () => {
      clearMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setBlocksData(bd)
  }, [bd, setBlocksData])

  return (
    <div style={{ height: '100%' }}>
      <div className="fullHeightTab">
        <div className="fixedHeader">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>{appState.teamContext ? appState.teamContext.name : 'MY BLOCKS'}</div>
            {user && (
              <IconButton onClick={onCreateClick}>
                <AddCircleIcon />
              </IconButton>
            )}
          </div>
          {appState.createBlock && (
            <CreateBlock createState={appState.createBlock} onDidSubmit={getMarket} global={!!admin} />
          )}
        </div>
        <div className="scrollableBody" style={{ marginTop: '5PX' }}>
          {!user ? (
            <div>
              <div>You need to login to save your blocks</div>
              <div>
                <AuthStatus mode={'standard'} />
              </div>
            </div>
          ) : (
            <div>
              {bd && ((bd.grouped && bd.grouped.length > 0) || (bd.ungrouped && bd.ungrouped.length > 0)) ? (
                <div className="list">
                  <div className="grouped section">
                    {bd.grouped &&
                      bd.grouped.map((bg) => (
                        <div className="group" key={`blockg${bg.id}`} style={{ marginBottom: '5px' }}>
                          <div className="header">{bg.name}</div>
                          <div style={{ paddingLeft: '5px' }}>
                            {bg.blocks.map((block) => (
                              <RenderBlock key={`blocklist${block.id}`} block={block} navigate={navigate} />
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="ungrouped section">
                    {bd.ungrouped && bd.ungrouped.length > 0 && (
                      <div className="header" style={{ marginTop: '10px' }}>
                        No Group
                      </div>
                    )}
                    {bd.ungrouped
                      .filter((b) => !!!b.is_global)
                      .map((block) => (
                        <RenderBlock key={`blocklist${block.id}`} block={block} navigate={navigate} />
                      ))}
                  </div>
                </div>
              ) : (
                !loading && 'No blocks meet your criteria'
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(MyBlocksTab)
